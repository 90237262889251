


















import {Vue, Component, Prop} from 'vue-property-decorator'
import {NftCollection} from '@/model/resource/NftCollection'

@Component({})
export default class NftCollectionImageRender extends Vue {
  @Prop({type: NftCollection, required: true}) nftCollection!: NftCollection

  get ghostMarketCollectionUrl() {
    return `${process.env.VUE_APP_BASE_GHOSTMARKET_COLLECTION_URL}${this.nftCollection.slug}`
  }
}
