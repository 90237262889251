import Parser from 'rss-parser'
import {$} from '@/facade'
import moment from 'moment'
import {NntTag} from '@/model/resource/NntTag'
import {intersection} from 'lodash'

export class NeoFeed {
  output: Parser.Output = {}

  async populate() {
    await $.await.run('neoFeedLoading', async () => {
      this.output = await new Parser().parseURL($.env.NEO_FEED_URL)

      this.output.items?.forEach(it => {
        const match = it.content?.match(/<img[^>]+src="(\S+)"[^>]*\/?>/)
        it.imageUrl = match?.[1] ?? null

        it.formattedPubDate = moment(it.pubDate).format(
          $.t('dateFormat.datePretty') as string
        )
      })
    })
  }

  itemsByTag(tags: NntTag[], limit = 3) {
    const categories = tags.map(it => it.title ?? '').filter(it => it) ?? []

    return (
      this.output.items
        ?.filter(
          it =>
            intersection(
              it.categories?.map(it => it.toLowerCase()),
              categories.map(it => it.toLowerCase())
            ).length
        )
        .slice(0, limit) ?? []
    )
  }
}
