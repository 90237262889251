












import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class SwapTokenAccountBalance extends Vue {
  @Prop({type: Number, required: true}) tokenAccountBalance!: number
}
