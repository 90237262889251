var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dapp-detailed-chart-filter"},[_c('a',{staticClass:"dapp-detailed-chart-filter__option dapp-detailed-chart-filter__option--left",class:{
      'dapp-detailed-chart-filter__option--active': _vm.isActive(
        _vm.DappDetailedGraphPeriod.ONE_DAY
      ),
    },on:{"click":function($event){return _vm.handleClick(_vm.DappDetailedGraphPeriod.ONE_DAY)}}},[_vm._v(" "+_vm._s(_vm.$translate('components.DappDetailedChartFilter.24h'))+" ")]),_c('a',{staticClass:"dapp-detailed-chart-filter__option border",class:{
      'dapp-detailed-chart-filter__option--active': _vm.isActive(
        _vm.DappDetailedGraphPeriod.SEVEN_DAYS
      ),
    },on:{"click":function($event){return _vm.handleClick(_vm.DappDetailedGraphPeriod.SEVEN_DAYS)}}},[_vm._v(" "+_vm._s(_vm.$translate('components.DappDetailedChartFilter.7d'))+" ")]),_c('a',{staticClass:"dapp-detailed-chart-filter__option border",class:{
      'dapp-detailed-chart-filter__option--active': _vm.isActive(
        _vm.DappDetailedGraphPeriod.FOURTEEN_DAYS
      ),
    },on:{"click":function($event){return _vm.handleClick(_vm.DappDetailedGraphPeriod.FOURTEEN_DAYS)}}},[_vm._v(" "+_vm._s(_vm.$translate('components.DappDetailedChartFilter.14d'))+" ")]),_c('a',{staticClass:"dapp-detailed-chart-filter__option dapp-detailed-chart-filter__option--right",class:{
      'dapp-detailed-chart-filter__option--active': _vm.isActive(
        _vm.DappDetailedGraphPeriod.THIRTY_DAYS
      ),
    },on:{"click":function($event){return _vm.handleClick(_vm.DappDetailedGraphPeriod.THIRTY_DAYS)}}},[_vm._v(" "+_vm._s(_vm.$translate('components.DappDetailedChartFilter.30d'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }