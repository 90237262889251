











































































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import SwapInput from '@/components/swapToken/SwapInput.vue'
import {PropType} from 'vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({
  components: {
    Tooltip,
    TooltipMobileContent,
    SwapInput,
  },
})
export default class SwapTokenToReceiveInput extends MixinScreenSize {
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem
  @Prop({type: ((null as unknown) as Object) as PropType<Boolean | null>})
  isValid!: boolean | null
  @Prop({type: String, required: true}) amount!: string
  @Prop({type: String, required: true}) amountInDollar!: string
  @Prop({type: Boolean, required: false, default: true})
  showDollarAmount!: boolean
  @Prop({type: Boolean, required: false, default: true}) showEstimated!: boolean

  mobileTooltipIsVisible = false
  alreadyImputed = false

  setMobileTooltip(val: boolean) {
    this.mobileTooltipIsVisible = val
  }

  handleInput(val: string) {
    this.alreadyImputed = true
    this.$emit('input', val)
  }

  focusOnInput() {
    const input = this.$el.querySelector(
      '.swap-token-to-receive-input__input'
    ) as HTMLInputElement
    input.focus()
  }
}
