




























import {Component, Prop} from 'vue-property-decorator'
import ExplorerCard from '@/components/cards/ExplorerCard.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {SimpleListTokensTableItemSchema} from '@/schema/resource/TokensTableItem/SimpleListTokensTableItemSchema'
import TokensPriceChart from '@/components/tokensprice/TokensPriceChart.vue'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import ScrollableTable from '@/components/ScrollableTable.vue'
import SwapModal from '@/components/swapToken/SwapModal.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import {Dapp} from '@/model/resource/Dapp'

@Component({
  components: {
    LastPriceUpdated,
    ScrollableTable,
    SwapModal,
    TokensPriceChart,
    ExplorerCard,
    PercentageProgress,
  },
})
export default class TokensPriceTable extends MixinScreenSize {
  @Prop({type: Number, default: null}) perPage!: number
  @Prop({type: Boolean, default: false}) showViewAll!: boolean
  @Prop({type: String, default: 'mb-32'}) adapExpansionClass!: string
  @Prop({type: Boolean, default: false}) isHomepage!: boolean
  @Prop({type: Boolean, default: false}) isDappDetailedPage!: boolean
  @Prop({type: Boolean, default: false}) isTokensPage!: boolean
  @Prop({type: Boolean, default: false}) showSwapButton!: boolean
  @Prop({type: Dapp, default: null}) dapp?: Dapp
  @Prop({type: Boolean, default: true}) showTokenTitle!: boolean
  @Prop({type: Object, required: false, default: null})
  tokensCollection?: TokensTableItemsCollection | null

  collection = new TokensTableItemsCollection()

  schema = new SimpleListTokensTableItemSchema()

  currentOrderBy: String | null = null
  swappableTokensSymbol: string[] = []

  excludeFields = !this.showSwapButton ? ['buttonSwap'] : []
  excludeTitles = !this.showTokenTitle ? ['tokens'] : []

  async created() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    if (this.tokensCollection) {
      this.collection = this.tokensCollection
    }

    if (this.isHomepage) {
      this.collection.orderBy = 'order'
      this.collection.minOrder = 1
      this.collection.perPage = this.perPage
      this.collection.asc = false
      this.collection.active = true
      return
    }

    if (this.dapp) {
      this.collection.slugDapp = this.dapp.slug

      if (this.dapp.blockchainVersion?.networkIdentifier) {
        this.collection.networkIdentifier = this.dapp.blockchainVersion.networkIdentifier
      }
    }

    this.collection.withoutTotal = true
    this.collection.orderBy = 'marketCap'
    this.collection.minOrder = 0
    this.collection.active = true
    this.collection.asc = false
    this.collection.perPage = this.collection.total
  }

  async handleOrderAscDesc(key: number) {
    const {params} = this.collection
    const value = this.schema.orderByList[key]

    if (value !== 'tokens') {
      params.ascending =
        value !== this.currentOrderBy ? false : !params.ascending
    } else {
      params.ascending =
        value !== this.currentOrderBy ? true : !params.ascending
    }

    params.orderBy = value

    this.collection.orderBy = value
    this.collection.asc = !params.ascending
    this.collection.clearFilters().addFilter(params)
    this.currentOrderBy = value
  }

  async populateResources() {
    const promises: Array<Promise<any>> = !this.tokensCollection
      ? [this.collection.expand()]
      : []

    if (this.showSwapButton) {
      this.swappableTokensSymbol = this.schema.swappableTokensSymbol
    }

    await this.$await.run(
      'loadTokensTableItems',
      async () => await Promise.all(promises)
    )

    this.$emit('last-price-updated', this.collection.lastTimeUpdatedFormatted)
  }
}
