













import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class SwapIndivisibleTokenBox extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: String, required: true}) text!: string
}
