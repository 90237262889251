













































































































import {Component, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import {Contract} from '@/model/resource/Contract'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {InputDate, InputSelect} from '@simpli/vue-input'
import DappDetailedStatsChart from '@/components/dappDetailed/DappDetailedStatsChart.vue'
import DappDetailedChartFilter from '@/components/dappDetailed/DappDetailedChartFilter.vue'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {ContractCounterGraphAssist} from '@/app/ContractCounterGraphAssist'

@Component({
  components: {
    DappDetailedStatsChart,
    DappDetailedChartFilter,
    PercentageProgress,
    InputSelect,
    InputDate,
  },
})
export default class DappDetailedStats extends MixinScreenSize {
  @Prop({type: String, default: null}) initialHash!: string | null
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Boolean}) readOnly?: boolean

  selectedContract: Contract | null = null
  legendContract = new Contract()

  contractsIdsAvailable: number[] =
    this.dapp.contract?.map(contract => contract.idContractPk) ?? []

  period: DappDetailedGraphPeriod = DappDetailedGraphPeriod.SEVEN_DAYS

  cache = new ContractCounterGraphAssist(
    this.period,
    this.contractsIdsAvailable
  )

  get contractOptions() {
    if (!this.dapp.contract || this.dapp.contract.length === 0) return []

    const contractOptions = this.dapp.contract.slice()

    const hasManyContracts = contractOptions.length > 1

    if (hasManyContracts) {
      this.legendContract.title = this.$tc(
        'components.dappDetailsCard.nContracts',
        contractOptions.length,
        {qty: contractOptions.length}
      )

      contractOptions.push(this.legendContract)
    }

    return contractOptions.reverse()
  }

  async mounted() {
    await this.populateResources()
  }

  async populateResources() {
    const initialContract = this.dapp.contract?.find(
      contract => contract.hash === this.initialHash
    )

    this.setSelectedContract(initialContract ?? null)
  }

  copyToClipboard() {
    if (!this.selectedContract?.hash) return

    this.$utils.copyToClipboard(this.selectedContract.hash)
    this.$toast.info('system.info.copiedToClipboard')
  }

  async onFilterChart(period: DappDetailedGraphPeriod) {
    await this.setPeriod(period)
  }

  async setSelectedContract(contract: Contract | null) {
    this.selectedContract = contract

    this.cache = new ContractCounterGraphAssist(
      this.period,
      contract?.idContractPk!
        ? [contract?.idContractPk!]
        : this.contractsIdsAvailable
    )

    await this.populateChart()
  }

  async setPeriod(period: DappDetailedGraphPeriod) {
    if (this.period === period) return

    this.period = period

    this.cache = new ContractCounterGraphAssist(
      this.period,
      this.selectedContract?.idContractPk
        ? [this.selectedContract?.idContractPk]
        : this.contractsIdsAvailable
    )

    await this.populateChart()
  }

  async populateChart() {
    await this.$await.run(`getDataChart_${this.dapp.$id}`, async () => {
      await this.cache.populate()
    })
  }
}
