






























import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({})
export default class SwapRouteDetails extends MixinScreenSize {
  @Prop({type: Array, required: true}) route!: TokensTableItem[]
}
