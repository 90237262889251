




























import {NetworkTagBackgroundColor} from '@/enums/NetworkTagBackgroundColor'
import {Vue, Component, Prop} from 'vue-property-decorator'
import {NetworkTagTextColor} from '@/enums/NetworkTagTextColor'

@Component
export default class NetworkTag extends Vue {
  @Prop({type: String, required: true}) blockchainTagName!: string
  @Prop({type: Boolean, required: false, default: false}) isLarge!: boolean

  get computedStyles() {
    const backgroundColor = this.getBackgroundColor()
    const textColor = this.getTextColor()
    return {
      backgroundColor,
      color: textColor,
    }
  }

  getBackgroundColor(): string {
    if (this.blockchainTagName.toUpperCase() in NetworkTagBackgroundColor) {
      return NetworkTagBackgroundColor[
        this.blockchainTagName.toUpperCase() as keyof typeof NetworkTagBackgroundColor
      ]
    }

    return '#525252'
  }

  getTextColor(): string {
    if (this.blockchainTagName.toUpperCase() in NetworkTagTextColor) {
      return NetworkTagTextColor[
        this.blockchainTagName.toUpperCase() as keyof typeof NetworkTagTextColor
      ]
    }

    return '#FFF'
  }
}
