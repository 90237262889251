










































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'

@Component
export default class DappDetailedChartFilter extends Vue {
  @Prop({type: Number, required: true}) period!: number

  DappDetailedGraphPeriod = DappDetailedGraphPeriod

  get selectedFilter() {
    return this.period
  }

  handleClick(days: DappDetailedGraphPeriod) {
    this.$emit('filterChart', days)
  }

  isActive(days: DappDetailedGraphPeriod) {
    return this.selectedFilter === days
  }
}
