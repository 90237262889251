
















































































































import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Component, Prop, Watch} from 'vue-property-decorator'
import SwapHelpTooltip from '@/components/swapToken/SwapHelpTooltip.vue'
import SwapHelpTooltipMobileContent from '@/components/swapToken/SwapHelpTooltipMobileContent.vue'

@Component({
  components: {
    SwapHelpTooltipMobileContent,
    SwapHelpTooltip,
  },
})
export default class SwapSettingsCard extends MixinScreenSize {
  @Prop({type: Number, required: true})
  deadlineInMinutes!: number
  @Prop({type: Number, required: true})
  allowedSlippageInPercentage!: number

  computedDeadline = String(this.deadlineInMinutes)
  computedAllowedSlippageInPercentage = String(this.allowedSlippageInPercentage)

  get isMinimumAllowedSlippage() {
    return this.allowedSlippageInPercentage === 0
  }

  handleBlurDeadline() {
    const MAX_VALUE = 60
    const MIN_VALUE = 1

    let valueNumber = Math.floor(Number(this.computedDeadline))

    if (valueNumber > MAX_VALUE) {
      valueNumber = MAX_VALUE
    } else if (valueNumber < MIN_VALUE) {
      valueNumber = MIN_VALUE
    }

    const value = valueNumber.toString()

    this.computedDeadline = value

    this.$emit('update:deadlineInMinutes', parseInt(value))
  }

  handleDecreaseAllowedSlippage() {
    if (this.isMinimumAllowedSlippage) return

    this.$emit(
      'update:allowedSlippageInPercentage',
      parseFloat((this.allowedSlippageInPercentage - 0.1).toFixed(1))
    )
  }

  handleIncreaseAllowedSlippage() {
    this.$emit(
      'update:allowedSlippageInPercentage',
      parseFloat((this.allowedSlippageInPercentage + 0.1).toFixed(1))
    )
  }

  @Watch('allowedSlippageInPercentage')
  onAllowedSlippageInPercentageChange() {
    this.computedAllowedSlippageInPercentage = String(
      this.allowedSlippageInPercentage
    )
  }
}
