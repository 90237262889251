

































import {Component, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import InactiveTag from '@/components/InactiveTag.vue'
import NetworkTag from '@/components/NetworkTag.vue'

@Component({
  components: {NetworkTag, InactiveTag},
})
export default class DappDetailedLogo extends MixinScreenSize {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Boolean}) showVersion?: boolean
}
