










import {Vue, Component, Prop} from 'vue-property-decorator'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'

@Component({
  components: {TooltipMobileContent},
})
export default class SwapHelpTooltipMobileContent extends Vue {
  @Prop({type: Boolean, required: true}) show!: boolean
}
