












































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {Explorer} from '@/model/resource/Explorer'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({})
export default class ExplorerCard extends Mixins(MixinScreenSize) {
  @Prop({type: Explorer, required: true}) explorer!: Explorer
  @Prop({type: Boolean}) rowMode?: boolean // ????
}
