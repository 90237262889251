/**
 * NftCollectionCollection
 * @author Lopes
 */

import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import moment from 'moment'
import {$} from '@/facade'
import {NftCollection} from '../resource/NftCollection'
import {AxiosResponse} from 'axios'

@HttpExclude()
export class NftCollectionCollection extends ExpansibleCollection<
  NftCollection
> {
  resource?: INftCollectionCollectionResourceHolder

  @RequestExpose() showBoughtInOneMonth: boolean | null = null
  @RequestExpose() networkIdentifier: string | null = null
  @RequestExpose() slugDapp: string | null = null

  constructor() {
    super(NftCollection)
  }

  get lastTimeUpdated() {
    const date = moment.utc(this.items[0]?.updatedAt)

    return date.format($.t('dateFormat.datetimeAlmostFull') as string)
  }

  async queryAsPage() {
    return this.listNftCollection()
  }

  async listNftCollection(): Promise<AxiosResponse<this>> {
    return await Request.get(`/client/nft-collection`, {
      params: this.params,
    })
      .name('loadNftCollection')
      .as(this)
      .getResponse()
  }
}

export interface INftCollectionCollectionResourceHolder {}
