



















































































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import {NeoFeed} from '@/app/NeoFeed'
import {DappInfo} from '@/model/resource/DappInfo'
import moment from 'moment'
import LatestNewsCard from '@/components/cards/LatestNewsCard.vue'

@Component({
  components: {LatestNewsCard},
  filters: {
    moment: (value: string) =>
      moment(value)
        .format('Do MMM YYYY')
        .toUpperCase(),
  },
})
export default class DappDetailedFeed extends Vue {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: NeoFeed, required: true}) feed!: NeoFeed
  @Prop({type: Boolean, required: true}) isDapp!: boolean
  @Prop({type: DappInfo, required: true}) dappInfo!: DappInfo

  get items() {
    return this.feed.itemsByTag(this.dappInfo.tags)
  }
}
