export enum SwapStep {
  FORM,
  REVIEW_FORM,
  WAITING_SIGNATURE,
  VALIDATING_TRANSACTION,
  SWAPPED,
  INSUFFICIENT_GAS,
  UNKNOWN_ERROR,
  DEADLINE_EXCEEDED,
}
