

















import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({})
export default class InactiveTag extends Vue {
  @Prop({type: Boolean, required: true}) isInactive!: boolean
}
