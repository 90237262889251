import { render, staticRenderFns } from "./PercentageProgressRender.vue?vue&type=template&id=6496f7e9"
import script from "./PercentageProgressRender.vue?vue&type=script&lang=ts"
export * from "./PercentageProgressRender.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports