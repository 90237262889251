

























































































import {Vue, Component, Prop} from 'vue-property-decorator'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({
  components: {Tooltip, TooltipMobileContent},
})
export default class SwapTokenDetails extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: Boolean, default: false}) showLockIcon!: boolean
  @Prop({type: Boolean, default: true, required: false})
  showEstimated!: boolean
  @Prop({type: Boolean, default: true, required: false})
  showDollarAmount!: boolean
  @Prop({type: Boolean, default: true, required: false})
  centralizedTitle!: boolean
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem
  @Prop({type: String, required: true}) amount!: string
  @Prop({type: String, required: true}) amountInDollar!: string
  @Prop({type: String, required: true}) bgColor!: string
  @Prop({type: String, required: false, default: 'text-slate-blue'})
  customTitleClass!: string

  mobileTooltipIsVisible = false

  get titleClass() {
    let adicionalClass
    if (this.centralizedTitle) {
      adicionalClass = 'text-center sm:text-left'
    } else {
      adicionalClass = 'text-left'
    }

    return `${this.customTitleClass} ${adicionalClass}`
  }

  get tokenImageAlt() {
    return `${this.token.symbol} icon`
  }

  setMobileTooltip(val: boolean) {
    this.mobileTooltipIsVisible = val
  }
}
