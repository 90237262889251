/**
 * TokensTableItem
 * @author Lopes
 */
import {IResource} from '@simpli/resource-collection'
import {TokensTableItemMarketInformation} from '@/model/resource/TokensTableItemMarketInformation'
import {Request} from '@simpli/serialized-request'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

export class TokensTableItem implements IResource {
  idToken: number = 0
  imageUrl: string | null = null
  symbol: string | null = null
  blockchainVersion: BlockchainVersion | null = null
  marketInformation: TokensTableItemMarketInformation | null = null

  get shouldDisplayMarketInformation(): boolean {
    return !!this.marketInformation?.currentPrice
  }

  get $id() {
    return this.idToken
  }

  get $tag() {
    return String(this.symbol)
  }

  get totalSupplyFormatted() {
    return Number(this.marketInformation?.totalSupply).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  get marketCapFormatted() {
    return Number(this.marketInformation?.marketCap).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  get priceFormatted() {
    return Number(this.marketInformation?.currentPrice).toLocaleString(
      'en-US',
      {
        maximumFractionDigits: 10,
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
      }
    )
  }

  get priceVariationLastDayFormatted(): number {
    return Number(this.marketInformation?.priceVariationLastDay ?? 0)
  }

  get priceVariationLastWeekFormatted(): number {
    return Number(this.marketInformation?.priceVariationLastWeek)
  }

  async persistContract() {
    return await Request.post(`/client/contract`, this)
      .name('persistContract')
      .asNumber()
      .getData()
  }
}
