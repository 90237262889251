













import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'

@Component({
  components: {PercentageProgress},
})
export default class PercentageProgressRender extends MixinScreenSize {
  @Prop({type: Number}) percentageValue?: number
  @Prop({type: Boolean}) showArrows?: boolean
  @Prop({type: Boolean}) isRounded?: boolean
  @Prop({type: String}) inputClass?: string
  @Prop({type: String}) inputClassImage?: string
  @Prop({type: String}) inputClassBorder?: string
}
