import { render, staticRenderFns } from "./NftCollectionTable.vue?vue&type=template&id=56e6037e&scoped=true"
import script from "./NftCollectionTable.vue?vue&type=script&lang=ts"
export * from "./NftCollectionTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e6037e",
  null
  
)

export default component.exports