























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class SwapErrorBox extends Vue {
  @Prop({type: String, required: false}) text?: string
  @Prop({
    type: String,
    required: true,
  })
  title!: string
  @Prop({type: Boolean, required: false, default: false}) isWarning?: boolean
}
