

















import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'

@Component({
  components: {PercentageProgress},
})
export default class NftCollectionMonthVolumeRender extends MixinScreenSize {
  @Prop({type: Number, required: true}) volume!: number
  @Prop({type: String, required: true}) price!: string
  @Prop({type: String, required: false}) inputClass?: string
}
