












import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Tooltip from '@/components/tooltip/Tooltip.vue'

@Component({
  components: {Tooltip},
})
export default class SwapHelpTooltip extends MixinScreenSize {
  @Prop({type: String, required: true}) label!: string
  @Prop({type: String, required: true}) text!: string

  handleClick(value: boolean) {
    this.$emit('onMobileOpen', value)
  }
}
