




































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import NetworkTag from '@/components/NetworkTag.vue'

@Component({
  components: {NetworkTag},
})
export default class TokenCellRender extends MixinScreenSize {
  @Prop({type: String}) symbol?: string
  @Prop({type: String}) slug?: string
  @Prop({type: String}) blockchainTagName?: string
  @Prop({type: String}) ownerAddress?: string
  @Prop({type: String}) titleDapp?: string

  get formattedAddress() {
    if (!this.ownerAddress) return undefined

    return `${this.ownerAddress.slice(0, 4)}...${this.ownerAddress.slice(-4)}`
  }

  copyToClipboard() {
    this.$utils.copyToClipboard(this.ownerAddress ?? '')
    this.$toast.info('system.info.copiedToClipboard')
  }
}
