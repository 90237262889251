import {IResource} from '@simpli/vue-input/lib/IResource'
import {ResponseSerialize, Request} from '@simpli/serialized-request'
import {Contract} from '@/model/resource/Contract'

export class ContractCounter implements IResource {
  idContractCounterPk: number = 0

  @ResponseSerialize(Contract)
  contract: Contract | null = null

  createdAt: string | null = null
  transactionCount: number | null = null

  get $id() {
    return this.idContractCounterPk
  }

  set $id(val) {
    this.idContractCounterPk = val
  }

  get $tag() {
    if (!this.contract) return ''

    return `${this.contract.title} ${
      this.contract.hash ? ' - ' + this.contract.hash : ''
    }`
  }
}
