























































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class PercentageProgress extends Vue {
  @Prop() percentageValue!: number | undefined
  @Prop({type: Boolean, default: true}) showArrows!: boolean
  @Prop({type: Boolean, default: true})
  isRounded!: boolean
  @Prop({type: String, default: 'text-lg md:text-3xl'})
  inputClass!: string
  @Prop({type: String, default: 'h-3 w-3 md:h-4 md:w-4'})
  inputClassImage!: string
  @Prop({type: String, default: 'h-6 w-6 md:h-8 md:w-8'})
  inputClassBorder!: string
}
