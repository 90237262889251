import {ContractCounter} from '@/model/resource/ContractCounter'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'
import moment from 'moment'
import {ContractCounterCollection} from '@/model/collection/ContractCounterCollection'

export class ContractCounterGraphAssist {
  collection: ContractCounterCollection = new ContractCounterCollection()

  totalContractCounterList: ContractCounter[] = []
  renderContractCounterList: ContractCounter[] = []
  referenceContractCounterList: ContractCounter[] = []

  contractIds: number[] = []
  daysRendered: number = 0
  daysFetched: number = 0

  period: DappDetailedGraphPeriod = DappDetailedGraphPeriod.SEVEN_DAYS

  constructor(period: DappDetailedGraphPeriod, contractIds: number[]) {
    this.contractIds = contractIds
    this.period = period
    this.daysRendered = period
    this.daysFetched = period * 2 // We need to fetch the double of days to compare the growth
  }

  get transactionCount() {
    if (this.renderContractCounterList.length === 0) {
      return 0
    }

    const transactionCount = this.renderContractCounterList.reduce(
      (acc, contractCounter) => acc + (contractCounter?.transactionCount || 0),
      0
    )

    return transactionCount
  }

  get referenceTransactionCount() {
    if (this.referenceContractCounterList.length === 0) {
      return 0
    }

    const transactionCount = this.referenceContractCounterList.reduce(
      (acc, contractCounter) => acc + (contractCounter?.transactionCount || 0),
      0
    )

    return transactionCount
  }

  get percentageGrow() {
    const growth =
      ((this.transactionCount - this.referenceTransactionCount) /
        this.referenceTransactionCount) *
      100

    if (isNaN(growth)) {
      return 0
    }

    if (!isFinite(growth)) {
      return 100
    }

    return growth
  }

  async populate() {
    const startDate = moment()
      .subtract(this.daysFetched, 'days')
      .format()

    this.collection.idContractFk = this.contractIds
    this.collection.startDate = startDate

    if (this.period === DappDetailedGraphPeriod.ONE_DAY) {
      this.totalContractCounterList = await this.collection.listHourlyContractCounter()

      this.renderContractCounterList = this.totalContractCounterList.filter(
        contractCounter =>
          moment(contractCounter.createdAt).isSameOrAfter(
            moment()
              .subtract(this.daysRendered + 1, 'days')
              .format()
          )
      )

      this.referenceContractCounterList = this.totalContractCounterList.filter(
        contractCounter =>
          moment(contractCounter.createdAt).isBefore(
            moment()
              .subtract(this.daysRendered + 1, 'days')
              .format()
          )
      )
      return
    }

    this.collection.interval = this.period
    this.renderContractCounterList = await this.collection.listDailyContractCounter()

    this.collection.isReference = true
    this.referenceContractCounterList = await this.collection.listDailyContractCounter()

    this.totalContractCounterList = this.renderContractCounterList.concat(
      this.referenceContractCounterList
    )
  }
}
