












































































































































































































































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import DappDetailedLogo from '@/components/dappDetailed/DappDetailedLogo.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {DappInfo} from '@/model/resource/DappInfo'

@Component({components: {DappDetailedLogo}})
export default class DappDetailedSummary extends Mixins(MixinScreenSize) {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Boolean, required: true}) isDapp!: boolean
  @Prop({type: DappInfo, required: true}) dappInfo!: DappInfo

  showMobileLinksMenu = false
}
