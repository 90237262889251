/**
 * ExplorerCollection
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Explorer} from '@/model/resource/Explorer'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'

@HttpExclude()
export class ExplorerCollection extends ExpansibleCollection<Explorer> {
  constructor() {
    super(Explorer)
  }

  resource?: IExplorerCollectionResourcesHolder
  requestName: string = 'listExplorer'

  @RequestExpose() idBlockchainVersionFk: number[] = []

  get explorerBlockchainVersion() {
    return (
      this.resource?.collectionBlockchainVersion.getManyIds(
        this.idBlockchainVersionFk
      ) ?? null
    )
  }

  set explorerBlockchainVersion(input) {
    this.idBlockchainVersionFk = input?.map(item => item?.$id) ?? []
  }

  get filterCount(): number {
    return [this.idBlockchainVersionFk.length, this.search ? 1 : 0].reduce(
      (sum, value) => (sum += value),
      0
    )
  }

  queryAsPage() {
    return this.listExplorer()
  }

  async listExplorer() {
    return await Request.get(`/client/explorer`, {params: this.params})
      .name('listExplorer')
      .as(this)
      .getResponse()
  }

  async listExportExplorer() {
    return await Request.get(`/client/explorer/export`, {
      params: this.params,
    })
      .name('listExportExplorer')
      .as(this)
      .getResponse()
  }
}

export interface IExplorerCollectionResourcesHolder {
  collectionBlockchainVersion: BlockchainVersionCollection
}
