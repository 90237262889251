/**
 * DappInfo
 * @author Raul
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {NntTag} from '@/model/resource/NntTag'
import {Token} from '@/model/resource/Token'
import {Wallet} from '@/model/resource/Wallet'
import {DappSocial} from '@/model/resource/DappSocial'

export class DappInfo implements IResource {
  @ResponseSerialize(NntTag)
  tags: NntTag[] = []

  @ResponseSerialize(Token)
  tokens: Token[] = []

  @ResponseSerialize(Wallet)
  wallets: Wallet[] = []

  @ResponseSerialize(DappSocial)
  socials: DappSocial[] | null = null

  get $id() {
    /* TODO: define the ID */
    return 0
  }

  set $id(val) {
    /* TODO: define the ID */
  }

  get $tag() {
    return String(this.$id)
  }

  get formattedTokens() {
    return this.tokens?.map(it => it.$tag).join(', ') ?? null
  }

  get formattedWallets() {
    return this.wallets?.map(it => it.$tag).join(', ') ?? null
  }

  /**
   * Gets infos of a given ID or slug of Dapp
   */
  async getInfoDapp(idOrSlug: number | string) {
    return await Request.get(`/client/dapp/${idOrSlug}/info`)
      .name('getInfoDapp')
      .as(this)
      .getData()
  }
}
