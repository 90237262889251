






































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {LatestNewsItem} from '@/model/resource/LatestNewsItem'
import moment from 'moment'

@Component({
  filters: {
    moment: (value: string) =>
      moment(value)
        .format('Do MMM YYYY')
        .toUpperCase(),
  },
})
export default class LatestNewsCard extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) news!: LatestNewsItem
  @Prop({type: Boolean, default: false}) isNewsFeed!: boolean
  @Prop({type: Boolean, default: false}) applyBorder!: boolean
  @Prop({type: Boolean, default: false}) applyShadow!: boolean

  returnRegexImg(item: string) {
    const regex = /https:+\/\/.[\S]*/gi
    const match = regex.exec(item)
    if (match) {
      return match[0]
    }
    return ''
  }
}
